import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import Tick from "./Tick";
import TickCross from "./TickCross";
import s from "./SelectOptionTask.module.css";
import useStore from "../helpers/use-store";

const SelectOptionTask = ({ text, onOptionClick, isCorrect }) => {
  const [currentOptionIsSelected, setCurrentOptionIsSelected] = useState(false);

  const { tasksStore } = useStore();
  const [style, setStyle] = useState("");

  let states = ["", "correct", "incorrect", "disabled"];

  let styleCheck = () => {
    if (currentOptionIsSelected) {
      if (isCorrect) {
        return states[1];
      }
      return states[2];
    }

    if (tasksStore.answerIsGiven && !currentOptionIsSelected && isCorrect) {
      return states[1];
    }

    if (tasksStore.answerIsGiven && !currentOptionIsSelected) {
      return states[3];
    }
  };

  let handleOptionClick = () => {
    if (tasksStore.answerIsGiven) return;
    if (currentOptionIsSelected) return;
    setStyle(styleCheck());
    setCurrentOptionIsSelected(true);

    onOptionClick();

    tasksStore.setAnswerIsGiven(true);
  };

  useEffect(() => {
    setStyle(styleCheck());
  }, [tasksStore.answerIsGiven]);

  const signRender = () => {
    if (isCorrect) return <Tick />;
    return <TickCross />;
  };

  return (
    <div
      className={`${s.option} ${s.card} ${s[style]} `}
      onClick={handleOptionClick}
    >
      <div className={s.option_text}>{text}</div>
      <div className={s.tick}>{currentOptionIsSelected && signRender()}</div>
    </div>
  );
};

export default observer(SelectOptionTask);
