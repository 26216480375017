import TaskSelect from "./TaskSelect.jsx";
import TaskReorder from "./TaskReorder.jsx";
import TaskPitch from "./TaskPitch.jsx";
import { observer } from "mobx-react-lite";

const TaskSwitcher = ({ type, title, options, optionClickCallback, id }) => {
  const renderer = () => {
    if (type === "select") {
      return (
        <TaskSelect
          taskId={id}
          title={title}
          options={options.options}
          correctOptionText={options.correct}
          optionClickCallback={optionClickCallback}
        />
      );
    } else if (type === "reorder") {
      return (
        <TaskReorder
          options={options}
          taskId={id}
          title={title}
          correctOption={options.correct}
          // onOptionClick={() => setShowTask(false)}
        />
      );
    } else if (type === "pitchtest") {
      return (
        <TaskPitch
          options={options}
          taskId={id}
          title={title}
          correctOption={options.correct}
          // onOptionClick={() => setShowTask(false)}
        />
      );
    }
  };

  return <>{renderer()}</>;
};

export default observer(TaskSwitcher);
