// import { http } from "@capacitor/core";
import { makeAutoObservable, runInAction, autorun } from "mobx";
import { gql, GraphQLClient } from "graphql-request";
import axios from "axios";

// import { secureStorageSet } from "../utils/utils";

export default class CommonStore {
  constructor() {
    makeAutoObservable(this);
  }

  setGlobalLoader = false;
  showPitchResult = false;
  pitchResult = [
    141.7662945166406, 141.67514842545197, 143.31984141302303,
    147.05967699087026, 146.9816991226422, 146.71039384583233,
    146.83437705218594, 146.78074081927895, 146.1469324988515,
    145.3315860885125, 143.6019628740279, 142.36028839015216,
    139.41651101111867, 136.7541071572027, 133.20353070222455,
    129.80505966809037, 120.16792379006056, 114.84014924577973,
    109.66261721052942, 105.8127802126268, 103.24236840905155,
    101.65538205288118, 99.13004506255905, 96.88120100173482, 92.93353346940877,
    91.28936944757571, 88.72954907541637, 86.90290683876688, 85.25373693596303,
    84.69070075601097, 96.70134618898923, 91.95202171365786, 91.7901529820101,
    89.36468997431857, 97.63329873968283, 97.16002137083976, 96.08794111250732,
    96.63648111166246, 95.58986785509683, 89.65332447633769, 85.50822983434341,
    82.18725814803584, 96.79242100855481, 97.50027736296909, 101.06653244023363,
    101.95336448599485, 103.3276164582568, 102.73779625316578,
    101.8678631652625, 100.8879106614207, 100.6690964461171, 102.01062392154881,
    102.14271641787279, 102.73397635340346, 104.43136247243231,
    104.69193185613635, 107.23761174698112, 110.68699188012492,
    112.88663611277529, 115.13667760354095, 117.7914302588651,
    121.98813033601984, 123.68851509402964, 123.51283990172789,
    122.65766246919812, 122.12790354558801, 120.83659155670227,
    119.04020768392962, 117.60398118347229, 116.90597132538984,
    114.27272813911783, 112.275454719782, 109.6965006099559, 106.70133801572442,
    105.80731431532625, 102.58456524413471, 100.33589397838071,
    98.88505191990288, 98.08413257296404, 97.0910625510916, 96.83037103647251,
  ];
  nativePitch = [
    107.31922762173275, 98.53935191759837, 97.11073418727129, 96.77176955436694,
    94.76788123970388, 94.60477247017059, 92.13897902055402, 91.98500337142424,
    92.11599222908302, 93.26066167978098, 105.25484026102893,
    110.19780662272495, 112.51444740260558, 115.41521109015964,
    116.2353638607336, 119.12124864744499, 119.89114945351565,
    120.22149615244648, 120.38704292968444, 121.06406070956622,
    121.37938933277793, 121.47356311319446, 121.96019853533019,
    122.83602555934915, 123.39051812760472, 123.72784661195661,
    123.55153084249181, 122.84644464727192, 122.46893492591873,
    122.67476453982162, 122.63725071587831, 122.38304885058993,
    121.91898613656141, 120.92968436267692, 119.57905680322769,
    118.69714311521044, 116.95998570597705, 106.73983836412823,
    110.1020314776348, 110.831153989058, 106.17162755034171, 102.51364463645874,
    98.75404295016246, 94.18387051106772, 92.06194954173048, 102.82687391773642,
    105.16184175201906, 105.55747015484614, 107.80829821398257,
    110.5045964503447, 110.63401600608869, 106.77052869720097,
    104.88348051208507, 102.80406211196566, 101.48769042649802,
    99.56953570276521, 97.63838602090249, 96.00523659077292, 94.56979327155,
    92.514618617538, 89.79736035421595, 86.7463262338564, 84.31074965175203,
    103.86182590158131, 92.40683978920326, 92.86237467438202, 92.39664711899643,
    91.96460705417458, 88.69568637600655, 88.9563085318673, 91.81046971562459,
    91.45606266720145, 91.95460680363108, 88.43492626826455, 91.5258442397144,
    89.42571937253899, 89.90415986942895, 90.79786252328256, 90.97407113233423,
    90.35056678976629,
  ];

  inViewIndex = 0;

  setInViewIndex(itemIndex) {
    this.inViewIndex = itemIndex;
  }

  posts = [
    {
      id: 1,
      video_url: "https://cdnv.boomstream.com/balancer/U3oW68Xy-GjaOc3Ru.mp4",
      video_type: "3n1",
      src_text_ids: [],
    },
    {
      id: 14,
      video_url: "https://cdnv.boomstream.com/balancer/gUV7Hpz0-btEr5vL3.mp4",
      video_type: "3n1",
      src_text_ids: [],
    },
    {
      id: 2,
      video_url: "https://cdnv.boomstream.com/balancer/LXeISuhM-btEr5vL3.mp4",
      video_type: "3n1",
      src_text_ids: [],
    },
    {
      id: 21,
      video_url: "https://cdnv.boomstream.com/balancer/3ZySTaFF-btEr5vL3.mp4",
      video_type: "3n1",
      src_text_ids: [],
    },
    {
      id: 22,
      video_url: "https://cdnv.boomstream.com/balancer/0RhS8Oms-btEr5vL3.mp4",
      video_type: "3n1",
      src_text_ids: [],
    },
    {
      id: 23,
      video_url: "https://cdnv.boomstream.com/balancer/g2az8XTO-btEr5vL3.mp4",
      video_type: "3n1",
      src_text_ids: [],
    },
    {
      id: 24,
      video_url: "https://cdnv.boomstream.com/balancer/PAsfJzEP-btEr5vL3.mp4",
      video_type: "3n1",
      src_text_ids: [],
    },
    {
      id: 25,
      video_url: "https://cdnv.boomstream.com/balancer/TDcMwpxV-btEr5vL3.mp4",
      video_type: "3n1",
      src_text_ids: [],
    },
  ];
  triggerPointAnimation = false;

  points = 100;

  setTriggerPointAnimation(value) {
    runInAction(() => {
      this.triggerPointAnimation = value;
    });
  }

  addPoints(value) {
    this.points = this.points + value;
    // runInAction(() => {
    autorun(() => {
      this.setTriggerPointAnimation(true);
    });
    // });
  }

  async getToken() {
    const resp = axios({
      url: "https://keycloak.mc.mckira.com/realms/hasura-app/protocol/openid-connect/token",
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: {
        username: "catalinpit",
        password: "12345",
        grant_type: "password",
        client_id: "hasura",
      },
    });
    // secureStorageSet("token", resp);
    console.log("resp", resp);

    return resp;
  }

  async getPosts() {
    let GET_POSTS = gql`
      query getPosts {
        posts(order_by: { id: desc }) {
          id
          video_url
          video_type
          src_text_ids
        }
      }
    `;
    const { posts } = await this.makeGQLRequest(GET_POSTS);
    console.log("posts", posts);
    runInAction(() => {
      this.posts = posts;
    });

    return posts;
  }

  async makeGQLRequest(query, variables) {
    try {
      const resp = await this.getToken();

      const customVariables = variables !== undefined ? variables : null;
      const endpoint = "https://graphql.mc.mckira.com/v1/graphql";
      const params = {
        headers: {
          // authorization: `Bearer ${await secureStorageGet("access_token")}`,
          authorization: `Bearer ${resp.data.access_token}`,
        },
      };

      // this.setGlobalLoader(true);

      const graphQLClient = new GraphQLClient(endpoint, params);
      // const gqlClient = await graphQLClient;
      const response = await graphQLClient.request(query, customVariables);
      console.log(response);
      // this.setGlobalLoader(false);

      return response;
    } catch (error) {
      console.error("error", error);
      return;
    }
  }
}
