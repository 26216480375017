import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonTabButton,
  IonNav,
  IonNavLink,
  IonButton,
} from "@ionic/react";
import { scanCircleOutline, closeCircleOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import UserFeed from "../components/UserFeed/UserFeed.jsx";
import StatusBar from "../components/StatusBar.jsx";
import ARtest from "../components/ARtest.jsx";
import TaskPitch from "../components/TaskPitch.jsx";
import TaskPitchResult from "../components/TaskPitchResult.jsx";

import useStore from "../helpers/use-store";
import "./Tab1.css";
import Tab2 from "./Tab2";

const Tab1: React.FC = () => {
  const { commonStore } = useStore();
  const [showScanner, setShowScanner] = useState(false);

  useEffect(() => {
    // let res = commonStore.getPosts();
    // console.log(res);
  }, []);

  return (
    <IonPage>
      <StatusBar />

      <div
        style={{
          position: "absolute",
          zIndex: 1,
          width: "60px",
          height: "60px",
          color: "white",
          background: "#00000070",
          borderRadius: "40px",
          right: "12px",
          bottom: "240px",
        }}
      >
        {/* <IonNavLink }>
        <IonButton>Go to Page Two</IonButton>
      </IonNavLink> */}

        <IonIcon
          style={{ width: "100%", height: "100%" }}
          icon={showScanner ? closeCircleOutline : scanCircleOutline}
          onClick={() => setShowScanner(!showScanner)}
        ></IonIcon>
      </div>

      {/* <IonNav root={() => <UserFeed />}></IonNav> */}
      {showScanner && <ARtest />}
      <IonContent fullscreen>
        <UserFeed />
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
