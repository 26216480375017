import { useState, useCallback, useMemo } from "react";
import useStore from "../helpers/use-store";
import { CreateAnimation } from "@ionic/react";
import { observer } from "mobx-react-lite";

import "./VideoPlayerFullScreen.css";
// import Axios from "axios";
// var videoSrc = "https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8";

const VideoPlayerFullScreen = observer(
  ({
    videoRef,
    cardData,
    inView,
    isPreload,
    isUnload,
    isDisplayBeforeInView,
    isDisplayAfterInView,
    onVideoEnd,
  }) => {
    const { postStore } = useStore();

    const [showLoader, setShowLoader] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [canPlay, setCanPlay] = useState(false);

    // const [loadingError, setLoadingError] = useState(false);

    const src = useMemo(() => cardData?.video_url, [cardData]);
    // const cardId = useMemo(() => cardData?._id.toString(), []);

    const useHookWithRefCallback = (
      ref,
      inView,
      pauseVideo,
      src,
      isPreload,
      isUnload,
      isDisplayBeforeInView,
      isDisplayAfterInView
    ) => {
      const setRef = useCallback(
        (node) => {
          ref.current = node;
          if (node === null) return;

          let hasEmptySrc = node.src === "about:blank" || node.src === "";
          let hasSameSrc = node.src.length > 11;
          let farTimer;

          if (inView) {
            if (!hasSameSrc) {
              node.src = src;
            }

            if (!pauseVideo) {
              setTimeout(() => node.play(), 200);
            }
          }

          if (!inView) {
            node.pause();
          }

          if (isUnload) {
            farTimer = setTimeout(() => {
              if (!hasEmptySrc) {
                node.src = "about:blank";

                // node.srcObject = null;
                node.load();
              }
            }, 350);
          }

          if (isDisplayAfterInView) {
            node.pause();

            if (hasEmptySrc) {
              setTimeout(() => (node.src = src), 200);
            }
          }
          if (isDisplayBeforeInView) {
            if (hasEmptySrc) {
              setTimeout(() => (node.src = src), 200);
            }
          }
          if (isPreload) {
          }
        },
        [
          inView,
          pauseVideo,
          isPreload,
          isUnload,
          isDisplayBeforeInView,
          isDisplayAfterInView,
        ]
      );

      return setRef;
    };

    const videoRef2 = useHookWithRefCallback(
      videoRef,
      inView,
      postStore.pauseVideo,
      src,
      isPreload,
      isUnload,
      isDisplayBeforeInView,
      isDisplayAfterInView
    );
    const vRef = videoRef2;

    const handleCanPlay = () => {
      // setLoadingError(false);
      setCanPlay(true);
      // postStore.itemsAmountToRender <= 1 && postStore.incrementItemsAmountToRender();
      // console.log("canPlay");
      // let timer = setTimeout(() => {
      //   postStore.itemsAmountToRender <= 1 && postStore.incrementItemsAmountToRender();
      // }, 200);
    };

    const handleOnPause = () => {
      //   if (!postStore.showModal && inView) {
      //     setShowLoader(true);
      //   }
      //   if (postStore.showModal) {
      //     setShowLoader(false);
      //   }
      //   console.log("handleOnPause");
      setIsPaused(true);
    };

    // const handleError = (e) => {
    //   console.error(e);
    //   setLoadingError(true);
    // };

    let loaderClass = `${
      showLoader ? "video-wrapper loader" : "video-wrapper "
    }`;

    return (
      <div
        className={loaderClass}
        style={{ postiton: "realtive", userEvent: "none" }}
      >
        {inView && !canPlay && (
          <CreateAnimation
            play={!canPlay}
            duration={800}
            iterations={Infinity}
            fromTo={[
              {
                property: "transform",
                fromValue: "rotate(0deg)",
                toValue: "rotate(360deg)",
              },
            ]}
          >
            <div className="loader-absolute"></div>
          </CreateAnimation>
        )}
        <video
          className="video-fullscreen "
          ref={vRef}
          playsInline
          // {...(isUnload && { src: null })}
          // preload="auto"
          controls
          onCanPlay={handleCanPlay}
          onEnded={onVideoEnd}
          // onPause={handleOnPause}
          // onError={handleError}
        />
      </div>
    );
  }
);

export default VideoPlayerFullScreen;
