import useStore from "../helpers/use-store";
import { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useInView } from "react-intersection-observer";
import { isInRange } from "../helpers/utils";

import "./PostPositionTracker.css";

const PostPositionTracker = ({ item, itemIndex, children, inViewCallback }) => {
  const [ref, inView] = useInView({
    threshold: 1,
  });

  const vRef = useRef(null);
  const { commonStore } = useStore();
  const [isPreload, setIsPreload] = useState(false);
  const [isUnload, setIsUnload] = useState(false);
  const [isDisplayBeforeInView, setIsDisplayBeforeInView] = useState(false);
  const [isDisplayAfterInView, setIsDisplayAfterInView] = useState(false);

  useEffect(() => {
    if (inView) {
      commonStore.setInViewIndex(itemIndex);
      inViewCallback();
    }
  }, [inView]);

  useEffect(() => {
    let displayBeforeInViewIndex = commonStore.inViewIndex + 2;
    let displayAfterInViewIndex = commonStore.inViewIndex - 2;

    setIsDisplayBeforeInView(
      isInRange(
        itemIndex,
        commonStore.inViewIndex + 1,
        displayBeforeInViewIndex
      )
    );
    setIsDisplayAfterInView(
      isInRange(itemIndex, displayAfterInViewIndex, commonStore.inViewIndex - 1)
    );

    setIsPreload(itemIndex > displayBeforeInViewIndex);
    setIsUnload(itemIndex < displayAfterInViewIndex);
  }, [commonStore.inViewIndex, inView, itemIndex]);

  return (
    <div
      className="slide"
      ref={ref}
      // data-stat={`isPreload:${isPreload}`}
      // data-stat2={`isUnload:${isUnload}`}
      // data-stat3={`isDisplayBeforeInView:${isDisplayBeforeInView}`}
      // data-stat4={`isDisplayAfterInView:${isDisplayAfterInView}`}
    >
      {children(
        item,
        vRef,
        inView,
        isPreload,
        isUnload,
        isDisplayBeforeInView,
        isDisplayAfterInView
      )}
    </div>
  );
};

// export default PostPositionTracker;
export default observer(PostPositionTracker);
