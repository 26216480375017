import TaskSlide from "./TaskSlide.jsx";
import TaskResultSlide from "./TaskResultSlide.jsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import useStore from "../helpers/use-store";

const TaskSlides = () => {
  let { tasksStore, postStore } = useStore();

  let [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  let [localInView, setLocalInView] = useState(false);

  let slideSwitchCallback = () => setCurrentTaskIndex(currentTaskIndex + 1);

  let postTasksFiltered = tasksStore.postTasks.filter(
    (task) => task.post_id == postStore.currentPostId
  );

  if (tasksStore.postTasks.length == 0) {
    return <></>;
  }

  if (currentTaskIndex < tasksStore.postTasks.length) {
    return (
      <>
        {tasksStore.postTasks
          .filter((task) => task.post_id == postStore.currentPostId)
          .map((task, index) => (
            <TaskSlide
              key={task.id}
              taskId={task.id}
              task={task.task_content}
              show={index == currentTaskIndex}
              slideSwitchCallback={slideSwitchCallback}
            />
          ))}
      </>
    );
  }

  if (
    currentTaskIndex >= postTasksFiltered.length &&
    postTasksFiltered.length !== 0
  ) {
    return (
      <>
        <TaskResultSlide />
      </>
    );
  }
};

export default observer(TaskSlides);
