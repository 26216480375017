import { observer } from "mobx-react-lite";
import s from "./RoundedBtn.module.css";

const RoundedBtn = ({ clickCallBack, children, disabled }) => {
  let isDisabled = disabled ? s.disabled : "";
  return (
    <div
      className={`${s.option} ${s.card} ${isDisabled} `}
      onClick={clickCallBack}
    >
      {children}
    </div>
  );
};

export default observer(RoundedBtn);
