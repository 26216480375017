import { observer } from "mobx-react-lite";
import { useState } from "react";
import s from "./TaskPitchRecordBtn.module.css";
import { CreateAnimation } from "@ionic/react";

const TaskPitchRecordBtn = ({ pointerUpHandle }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [inViewIndex, setInViewIndex] = useState(true);
  const [itemIndex, setItemIndex] = useState(true);
  return (
    <>
      <div className={s.btn_container}>
        <svg
          onPointerDown={() => {
            setIsRecording(true);
          }}
          onPointerUp={() => {
            setIsRecording(false);
            pointerUpHandle();
          }}
          className={s.btn}
          style={{ overflow: "visible" }}
          width="44"
          height="44"
          viewBox="0 0 69 69"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {isRecording && (
            <CreateAnimation
              play={isRecording && inViewIndex === itemIndex}
              // stop={!store.isRecording}
              duration={2000}
              iterations={Infinity}
              fromTo={[
                {
                  property: "transform",
                  fromValue: "rotate(380deg) scale(2.5)",
                  toValue: "rotate(0deg) scale(2.5)",
                },
              ]}
            >
              <g style={{ transformOrigin: "center" }}>
                <path
                  opacity="0.5"
                  d="M48.7151 11H21.285C15.6048 11 11 15.6048 11 21.285V48.715C11 54.3953 15.6048 59 21.285 59H48.7151C54.3953 59 59 54.3953 59 48.715V21.285C59 15.6048 54.3953 11 48.7151 11Z"
                  fill="#00D06B"
                />
                <path
                  opacity="0.7"
                  d="M27.3128 7.69429L7.69439 27.3127C3.63164 31.3755 3.63164 37.9625 7.69439 42.0252L27.3128 61.6437C31.3756 65.7064 37.9626 65.7064 42.0253 61.6437L61.6438 42.0252C65.7065 37.9625 65.7065 31.3755 61.6438 27.3127L42.0253 7.69429C37.9626 3.63154 31.3756 3.63154 27.3128 7.69429Z"
                  fill="#00D06B"
                />
              </g>
            </CreateAnimation>
          )}

          <path
            d="M34.5 67C52.4493 67 67 52.4493 67 34.5C67 16.5507 52.4493 2 34.5 2C16.5507 2 2 16.5507 2 34.5C2 52.4493 16.5507 67 34.5 67Z"
            fill="#01D970"
          />
          <path
            d="M46.4275 32.6531C46.9244 32.6065 47.365 32.9715 47.4116 33.4684C47.6519 36.032 47.1936 39.6111 45.235 42.656C43.2461 45.748 39.7616 48.2043 34.1533 48.5212C34.1138 48.5234 34.0743 48.523 34.0349 48.5201C28.0969 48.0755 24.7767 45.2946 22.9886 42.075C21.2357 38.9191 21 35.429 21 33.5527C21 33.0537 21.4045 32.6491 21.9036 32.6491C22.4026 32.6491 22.8072 33.0537 22.8072 33.5527C22.8072 35.321 23.0382 38.4424 24.5685 41.1976C26.0579 43.8792 28.8221 46.301 34.1106 46.7134C39.1287 46.4143 42.0583 44.2539 43.715 41.6783C45.4085 39.0456 45.8237 35.8927 45.6122 33.6371C45.5656 33.1402 45.9307 32.6996 46.4275 32.6531Z"
            fill="#FEFEFE"
          />
          <path
            d="M34.2239 47.6509C34.856 47.6509 35.3685 47.9933 35.3685 48.4157V54.9361C35.3685 55.3585 34.856 55.7009 34.2239 55.7009C33.5918 55.7009 33.0793 55.3585 33.0793 54.9361V48.4157C33.0793 47.9933 33.5918 47.6509 34.2239 47.6509Z"
            fill="#FEFEFE"
          />
          <rect
            x="28.9999"
            y="16"
            width="11"
            height="26"
            rx="5.5"
            stroke="#FEFEFE"
            strokeWidth="2"
          />
        </svg>
        {/* <div className={s.btn_container} onPointerUp={onClick}> */}
        {/* <img src="/assets/icon/mic-icon.svg" /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default observer(TaskPitchRecordBtn);
