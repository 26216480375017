import { observer } from "mobx-react-lite";
import TaskPitchResultChart from "./TaskPitchResultChart";
import s from "./TaskPitchResult.module.css";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonTabButton,
  IonNav,
  IonButtons,
  IonBackButton,
} from "@ionic/react";
const TaskPitchResult = () => {
  const percentage = Math.floor(Math.random() * 80);

  return (
    <div className={s.container}>
      <div className={s.copy}>Ваш результат</div>
      <h1 className={s.percentage_title}>{percentage}%</h1>

      <div className={s.chart_container}>
        <IonBackButton>go back</IonBackButton>
        <IonButtons slot="start"></IonButtons>
        <TaskPitchResultChart />
      </div>
    </div>
  );
};

export default observer(TaskPitchResult);
