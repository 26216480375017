import { observer } from "mobx-react-lite";
import s from "./TickCross.module.css";

const TickCross = () => (
  <div className={s.tick_container}>
    <img src="/assets/icon/cross.svg" />
  </div>
);

export default observer(TickCross);
