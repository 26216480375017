import PostContainer from "../PostContainer";
import useStore from "../../helpers/use-store";
import { observer } from "mobx-react-lite";
import s from "./UserFeed.module.css";
import {
  IonContent,
  IonNavLink,
  IonButton,
  IonButtons,
  IonBackButton,
} from "@ionic/react";
import TaskPitchResult from "../TaskPitchResult";
let vh = window.innerHeight * 0.01;

const UserFeed = () => {
  const { commonStore } = useStore();
  return (
    <>
      {/* <IonButtons slot="start">
        <IonBackButton>awdwadw</IonBackButton>
      </IonButtons>
      <IonNavLink
        routerDirection="forward"
        component={() => <TaskPitchResult />}
      >
        <IonButton>Go to Page Two</IonButton>
      </IonNavLink> */}
      <div
        className={s.userfeed_wrapper}
        style={{ height: `calc(var(${vh}, 1vh) * 100)` }}
      >
        {commonStore.posts.map((post, index) => {
          return <PostContainer post={post} itemIndex={index} key={index} />;
        })}
      </div>
    </>
  );
};

export default observer(UserFeed);
