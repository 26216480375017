import { observer } from "mobx-react-lite";
import { makeAutoObservable, runInAction } from "mobx";
import { gql } from "graphql-request";

export default class TasksStore {
  constructor(CommonStore, postStore) {
    makeAutoObservable(this);
    // console.log(postStore);
    this.commonStore = CommonStore;
    this.postStore = postStore;
    console.log(this.postStore);
  }

  postTasks = [
    {
      id: 1,
      post_id: 1,
      task_content: {
        type: "pitchtest",
        title: "qwdqwdqw",
        options: {
          options: ["dwqd", "dqwd", "qqerrffe", "e1d212d12"],
          correct: "dwqd",
        },
        id: 1,
      },
    },

    {
      id: 2,
      post_id: 14,
      task_content: {
        type: "select",
        title: "qwdqwdqw",
        options: {
          options: ["dwqd", "dqwd", "qqerrffe", "e1d212d12"],
          correct: "dwqd",
        },
        id: 1,
      },
    },
    {
      id: 3,
      post_id: 2,
      task_content: {
        type: "pitchtest",
        title: "qwdqwdqw",
        options: {
          options: ["dwqd", "dqwd", "qqerrffe", "e1d212d12"],
          correct: "dwqd",
        },
        id: 1,
      },
    },
    {
      id: 4,
      post_id: 2,
      task_content: {
        type: "select",
        title: "qwdqwdqw",
        options: {
          options: ["dwqd", "dqwd", "qqerrffe", "e1d212d12"],
          correct: "dwqd",
        },
        id: 1,
      },
    },
    {
      id: 5,
      post_id: 21,
      task_content: {
        type: "select",
        title: "qwdqwdqw",
        options: {
          options: ["dwqd", "dqwd", "qqerrffe", "e1d212d12"],
          correct: "dwqd",
        },
        id: 1,
      },
    },
    {
      id: 6,
      post_id: 21,
      task_content: {
        type: "select",
        title: "qwdqwdqw",
        options: {
          options: ["dwqd", "dqwd", "qqerrffe", "e1d212d12"],
          correct: "dwqd",
        },
        id: 1,
      },
    },
    {
      id: 7,
      post_id: 22,
      task_content: {
        type: "select",
        title: "qwdqwdqw",
        options: {
          options: ["dwqd", "dqwd", "qqerrffe", "e1d212d12"],
          correct: "dwqd",
        },
        id: 1,
      },
    },

    {
      id: 8,
      post_id: 23,
      task_content: {
        type: "select",
        title: "qwdqwdqw",
        options: {
          options: ["dwqd", "dqwd", "qqerrffe", "e1d212d12"],
          correct: "dwqd",
        },
        id: 1,
      },
    },
    {
      id: 9,
      post_id: 23,
      task_content: {
        type: "select",
        title: "qwdqwdqw",
        options: {
          options: ["dwqd", "dqwd", "qqerrffe", "e1d212d12"],
          correct: "dwqd",
        },
        id: 1,
      },
    },
    {
      id: 10,
      post_id: 25,
      task_content: {
        type: "select",
        title: "qwdqwdqw",
        options: {
          options: ["dwqd", "dqwd", "qqerrffe", "e1d212d12"],
          correct: "dwqd",
        },
        id: 1,
      },
    },
  ];

  currentTaskId = null;

  answerIsGiven = false;

  tasksStates = [];

  // setCurrentPostId(postId) {
  //   this.currentPostId = postId;
  // }

  setAnswerIsGiven(value) {
    this.answerIsGiven = value;
  }

  get taskIsAnswered() {
    return (
      this.tasksStates.filter((t) => {
        return t.taskId === this.currentTaskId;
      }).length > 0
    );
  }

  get getCurrentTaskStates() {
    return this.tasksStates.filter((t) => t.taskId === this.currentTaskId);
  }

  setCurrentTaskState(taskId, isCorrect, postId) {
    // console.log(taskId, isCorrect, postId);
    let didUpdateTaskState = false;
    // let doesExists = this.tasksStates.some((o) => o.taskId == taskId);

    let taskStateProxy = this.tasksStates.map((task) => {
      if (task.taskId == taskId) {
        task.isCorrect = isCorrect;
        // task.postId = postId;
        didUpdateTaskState = true;
      }
    });

    this.tasksStates = taskStateProxy;

    if (!didUpdateTaskState) {
      this.tasksStates.push({
        postId: postId,
        taskId: taskId,
        isCorrect: isCorrect,
      });
    }

    // console.log("this.tasksStates", this.tasksStates);
  }

  setCurrentTaskId(value) {
    this.currentTaskId = value;
  }

  async sendTaskResult() {
    let SEND_TASK_RESULT = gql`
      mutation sendTaskResult(
        $post_id: Int!
        $task_id: Int!
        $is_correct: Boolean!
      ) {
        insert_post_tasks_results(
          objects: {
            post_id: $post_id
            task_id: $task_id
            is_correct: $is_correct
          }
        ) {
          affected_rows
        }
      }
    `;

    const variables = {
      post_id: this.postStore.currentPostId,
      task_id: this.currentTaskId,
      is_correct: this.getCurrentTaskStates[0].isCorrect,
    };
    // console.log("variables", variables);
    const { affected_rows } = await this.commonStore.makeGQLRequest(
      SEND_TASK_RESULT,
      variables
    );

    console.log("affected_rows", affected_rows);
  }

  async getTasks() {
    let GET_TASKS = gql`
      query getTasksForPostByPostId($post_id: Int!) {
        tasks: posts_tasks(
          where: { post_id: { _eq: $post_id } }
          order_by: { id: desc }
        ) {
          id
          post_id
          task_content {
            type
            title
            options
            id
          }
        }
      }
    `;

    const variables = { post_id: this.postStore.currentPostId };
    console.log("variables", variables);
    const { tasks } = await this.commonStore.makeGQLRequest(
      GET_TASKS,
      variables
    );

    console.log("tasks", tasks);

    if (tasks.length === 0) return;

    runInAction(() => {
      this.postTasks = [...this.postTasks, ...tasks];
    });

    // console.log(" this.postTasks", this.postTasks);

    return tasks;
  }
}
