import { observer } from "mobx-react-lite";
import s from "./Tick.module.css";

const Tick = () => (
  <div className={s.tick_container}>
    <img src="/assets/icon/tick.svg" />
  </div>
);

export default observer(Tick);
