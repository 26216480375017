import { ARCanvas, NFTMarker } from "../components/react-three-arnft/src/index";

const ARtest = () => {
  return (
    // <div style={{}}>
    <ARCanvas
      interpolationFactor={1}
      onCreated={({ gl }) => {
        gl.setSize(window.innerWidth, window.innerHeight);
      }}
      onMarkerLost={() => {
        console.log("onMarkerLost");
      }}
      onMarkerFound={() => {
        console.log("onMarkerFound");
      }}
    >
      <NFTMarker
        url={[
          "/assets/nft_markers/page24 300dpi_contrast2.fset",
          "/assets/nft_markers/page24 300dpi_contrast2.fset3",
          "/assets/nft_markers/page24 300dpi_contrast2.iset",
          "/assets/nft_markers/page25 300dpi.fset",
          "/assets/nft_markers/page25 300dpi.fset3",
          "/assets/nft_markers/page25 300dpi.iset",
          "/assets/nft_markers/page21 300dpi.fset",
          "/assets/nft_markers/page21 300dpi.fset3",
          "/assets/nft_markers/page21 300dpi.iset",
        ]}
      >
        <mesh scale={[200, 200, 10]}>
          <boxGeometry args={[1, 1, 1]} />
          <meshNormalMaterial opacity={0.5} transparent={true} />
        </mesh>
      </NFTMarker>
      <ambientLight />
    </ARCanvas>
    // </div>
  );
};

export default ARtest;
