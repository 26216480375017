import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";

let isIncluded = (arr, el) => arr.includes(el);
let filterItem = (arr, el) => arr.filter((a) => a !== el);
let scrambleWords = (array) => {
  let arraysAcc = [...array];

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  // Used like so
  // var arr = [2, 11, 37, 42];
  console.log(arraysAcc);
  return shuffle(arraysAcc);
};

const secureStorageSet = async ({ key, value }) => {
  const result = await SecureStoragePlugin.set({ key, value });
  return result;
};
const secureStorageGet = async (key) => {
  try {
    const result = await SecureStoragePlugin.get({ key });
    return result.value;
  } catch (err) {
    console.error(err);
    return "Not found";
  }
};

const secureStorageRemove = async (key) => {
  const result = await SecureStoragePlugin.remove({ key });
  return result.value;
};

const secureStorageKeys = async () => {
  const result = await SecureStoragePlugin.keys();
  return result.value;
};
const secureStorageClear = async () => {
  const result = await SecureStoragePlugin.clear();
  return result.value;
};

export {
  secureStorageSet,
  secureStorageGet,
  secureStorageRemove,
  secureStorageKeys,
  secureStorageClear,
  isIncluded,
  filterItem,
  scrambleWords,
};
