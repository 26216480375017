import { observer } from "mobx-react-lite";
import SelectOptionTask from "./SelectOptionTask";
import s from "./TaskSelect.module.css";

const TaskSelect = ({
  taskId,
  title,
  options,
  correctOptionText,
  optionClickCallback,
}) => {
  console.log(options);
  return (
    <>
      <div className={s.task}>Выберите правильный вариант</div>
      <div className={s.task_content_wrapper}>
        <div className={s.question_wrapper}>{title}</div>
        <div className={`${s.options_wrapper} ${s.grid}`}>
          {options.map((text, index) => (
            <SelectOptionTask
              taskId={taskId}
              text={text}
              isCorrect={correctOptionText === text}
              onOptionClick={() => optionClickCallback()}
              key={taskId + text}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default observer(TaskSelect);
