import { observer } from "mobx-react-lite";
import s from "./TaskResultSlide.module.css";
import SlideContainer from "./SlideContainer";

const TaskResultSlide = () => (
  <div className={s.slide_container}>
    <SlideContainer title="Отлично">
      <div className={s.task}>Вы выполнили все задания</div>
    </SlideContainer>
  </div>
);

export default observer(TaskResultSlide);
