import PostPositionTracker from "./PostPositionTracker";
import VideoPlayerFullScreen from "./VideoPlayerFullScreen";
import TaskSlides from "./TaskSlides.jsx";
import { observer } from "mobx-react-lite";
import s from "./PostContainer.module.css";
import { useState } from "react";
import useStore from "../helpers/use-store";
import TaskPitchRecordBtn from "./TaskPitchRecordBtn";
import TaskPitchResult from "./TaskPitchResult";

const PostContainer = ({ post, itemIndex }) => {
  let [showTask, setShowTask] = useState(false);
  let [taskIsComplete, setTaskIsComplete] = useState(false);
  const { postStore, tasksStore, commonStore } = useStore();

  const handleInView = (postId) => {
    postStore.setCurrentPostId(postId);
    console.log("tasksStore.postTasks", tasksStore.postTasks);

    if (!tasksStore.postTasks.some((pt) => pt.post_id === postId)) {
      // tasksStore.getTasks();
    }
  };

  return (
    <div className={s.container}>
      <div className={s.content}>
        <PostPositionTracker
          itemIndex={itemIndex}
          key={post.id}
          item={post}
          inViewCallback={() => handleInView(post.id)}
        >
          {(
            item,
            vRef,
            inView,
            isPreload,
            isUnload,
            isDisplayBeforeInView,
            isDisplayAfterInView
          ) => (
            <>
              {(postStore.currentPostId == post.id &&
                tasksStore.postTasks.filter(
                  (task) => task.post_id == postStore.currentPostId
                )[0].task_content.type) == "pitchtest" && (
                <TaskPitchRecordBtn pointerUpHandle={() => setShowTask(true)} />
              )}

              {/* {showTask && !taskIsComplete && <TaskPitchResult />} */}
              <VideoPlayerFullScreen
                videoRef={vRef}
                cardData={item}
                inView={inView}
                isPreload={isPreload}
                isUnload={isUnload}
                isDisplayBeforeInView={isDisplayBeforeInView}
                isDisplayAfterInView={isDisplayAfterInView}
                onVideoEnd={() => {
                  setShowTask(true);
                  commonStore.addPoints(10);
                }}
              />
              {showTask && <TaskSlides />}
            </>
          )}
        </PostPositionTracker>
      </div>
    </div>
  );
};

export default observer(PostContainer);
