import { makeAutoObservable, runInAction } from "mobx";

export default class PostStore {
  constructor() {
    makeAutoObservable(this);
  }
  currentPostId = null;

  setCurrentPostId(postId) {
    this.currentPostId = postId;
    console.log(postId);
  }
}
