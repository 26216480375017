import { observer } from "mobx-react-lite";
import { useState, useRef, useEffect } from "react";
import useStore from "../helpers/use-store";
import { isIncluded, scrambleWords } from "../utils/utils";
import s from "./TaskReorder.module.css";
import TaskReorderBar from "./TaskReorderBar";
import TaskReorderBtn from "./TaskReorderBtn";

const TaskReorder = ({ options, title, correctOption }) => {
  const wordRef = useRef(null);
  const [words, setWords] = useState(options.options);
  const [answerResult, setAnswerResult] = useState();
  const { tasksStore } = useStore();
  const [currectOrder, setCurrectOrder] = useState(["не", "из", "Мексики"]);
  const [selectedIndex, setSelectedIndex] = useState([]);

  let handleSelectClick = (elemIndex) => {
    if (tasksStore.answerIsGiven) return;
    if (!isIncluded(selectedIndex, elemIndex)) {
      setSelectedIndex([...selectedIndex, elemIndex]);
    }
  };

  let handleRemoveSelectClick = (elemIndex) => {
    setSelectedIndex(selectedIndex.slice(0, -1));
  };

  useEffect(() => {
    setWords(scrambleWords(words));
  }, []);

  useEffect(() => {
    if (
      currectOrder.join(" ") == selectedIndex.map((s) => words[s]).join(" ")
    ) {
      setAnswerResult(true);
    }
  }, [selectedIndex]);

  return (
    <>
      <div className={s.task}>Переведите предложение</div>
      <div className={s.task_content_wrapper}>
        <div className={s.question_wrapper}>{title}</div>
        <TaskReorderBar
          onRemoveCallback={handleRemoveSelectClick}
          selectedWords={selectedIndex.map((selIndex) => words[selIndex])}
          correctOption={correctOption}
        />

        <div className={s.options_wrapper}>
          {words.map((text, index) => (
            <TaskReorderBtn
              ref={wordRef}
              key={text + index}
              elemIndex={index}
              onClickHandler={handleSelectClick}
              text={text}
              disabled={isIncluded(selectedIndex, index)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default observer(TaskReorder);
