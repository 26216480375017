import { observer } from "mobx-react-lite";
import { useState, useRef, useEffect } from "react";
import useStore from "../helpers/use-store";
import s from "./TaskReorderBar.module.css";
import Tick from "./Tick";
import TickCross from "./TickCross";

const TaskReorderBar = ({ selectedWords, correctOption, onRemoveCallback }) => {
  // const wordRef = useRef(null);
  const { tasksStore } = useStore();
  const [style, setStyle] = useState("");
  const [isCorrect, setIsCorrect] = useState(null);

  useEffect(() => {
    if (correctOption.split(" ").length !== selectedWords.length) return;
    tasksStore.setAnswerIsGiven(true);
  }, [selectedWords]);

  useEffect(() => {
    if (!tasksStore.answerIsGiven) return;
    setIsCorrect(selectedWords.join(" ") === correctOption);
  }, [tasksStore.answerIsGiven]);

  useEffect(() => {
    if (!tasksStore.answerIsGiven) return;
    setStyle(isCorrect ? "correct" : "incorrect");
  }, [isCorrect]);

  let handleRemoveSelectClick = (elemIndex) => {
    onRemoveCallback(elemIndex);
    if (tasksStore.answerIsGiven) {
      tasksStore.setAnswerIsGiven(false);
      setIsCorrect(null);
      setStyle("");
    }
  };

  const signRender = () => {
    if (!tasksStore.answerIsGiven) return;
    if (isCorrect) return <Tick />;
    return <TickCross />;
  };

  return (
    <>
      <div className={s.bar}>
        {selectedWords.length > 0 && (
          <>
            <div
              className={`${s.filler} ${s[style]}`}
              onClick={() => handleRemoveSelectClick(selectedWords.length - 1)}
            >
              {selectedWords.join(" ")}
            </div>
          </>
        )}
        <div className={s.tick_wrapper}>{signRender()}</div>
      </div>
    </>
  );
};

export default observer(TaskReorderBar);
