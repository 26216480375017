import { forwardRef } from "react";
import s from "./TaskReorderBtn.module.css";

const TaskReorderBtn = forwardRef(
  ({ text, onClickHandler, elemIndex, disabled }, ref) => {
    let disabledClass = disabled ? s.disabled : "";

    const handleClick = (elemIndex) => {
      onClickHandler(elemIndex);
    };

    return (
      <button
        className={`${s.button} ${s.card} ${s.option} ${disabledClass}`}
        onClick={() => handleClick(elemIndex)}
        ref={ref}
      >
        {text}
      </button>
    );
  }
);

export default TaskReorderBtn;
