import { observer } from "mobx-react-lite";
import { useState } from "react";
import useStore from "../helpers/use-store";
import s from "./StatusBar.module.css";

const StatusBar = () => {
  const { commonStore } = useStore();
  //   const [animate, setAnimate] = useState("animate");

  return (
    <div
      className={`${s.wrapper} ${
        s[!commonStore.triggerPointAnimation ? "" : "animate"]
      }`}
    >
      <div
        className={`${s.status_bar} ${s.pulser}`}
        onAnimationEnd={() => commonStore.setTriggerPointAnimation(false)}
        // onClick={() => commonStore.setTriggerPointAnimation(true)}
      >
        {commonStore.points}
      </div>
      <div className={s.points}>+10</div>
    </div>
  );
};

export default observer(StatusBar);
