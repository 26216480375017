import { observer } from "mobx-react-lite";
import s from "./SlideContainer.module.css";

const SlideContainer = ({ children, title }) => {
  return (
    <div className={s.container}>
      <div className={`${s.title}`}>{title}</div>
      <div className={s.task_container}>{children}</div>
    </div>
  );
};

export default observer(SlideContainer);
