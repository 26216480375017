import RoundedBtn from "./RoundedBtn.jsx";
import TaskSwitcher from "./TaskSwitcher.jsx";
import SlideContainer from "./SlideContainer.jsx";
import { observer } from "mobx-react-lite";
import s from "./TaskSlide.module.css";
import { useState } from "react";
import useStore from "../helpers/use-store";

const TaskSlide = ({ task, show, slideSwitchCallback }) => {
  const { type, title, options } = task;

  let [nextBtnStyleClassName, setNextBtnStyleClassNam] = useState("");
  const { tasksStore } = useStore();

  const handleNextClick = () => {
    slideSwitchCallback();
    tasksStore.setAnswerIsGiven(false);
  };

  const optionClickCallback = () => {
    tasksStore.setAnswerIsGiven(true);
  };

  if (!show) return <div></div>;

  return (
    <div className={s.slide_container}>
      <SlideContainer title="Задание">
        <TaskSwitcher
          type={type}
          title={title}
          options={options}
          optionClickCallback={optionClickCallback}
        />
        <div className={s.next_btn_wrapper}>
          <RoundedBtn
            clickCallBack={() => handleNextClick()}
            styleClassName={nextBtnStyleClassName}
            disabled={!tasksStore.answerIsGiven}
          >
            Next
          </RoundedBtn>
        </div>
      </SlideContainer>
    </div>
  );
};

export default observer(TaskSlide);
