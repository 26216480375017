import { observer } from "mobx-react-lite";
import TaskPitchResult from "./TaskPitchResult";

import s from "./TaskPitch.module.css";

const TaskPitch = () => {
  return (
    <>
      <TaskPitchResult />
    </>
  );
};

export default observer(TaskPitch);
