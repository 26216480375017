/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-pascal-case */

import { Canvas } from "@react-three/fiber";
import React, { useRef } from "react";
import { ARNftProvider } from "../arnftContext";

import Loader from "./loader";

const ARCanvas = ({
  arEnabled = true,
  interpolationFactor = 1,
  children,
  onMarkerLost,
  onMarkerFound,
  ...props
}) => {
  const ref = useRef();

  return (
    <>
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          zIndex: 2,
          color: "white",
          display: "flex",
          // height: "100%",
          width: "100%",
          justifyContent: "center",
          // alignItems: "center",
        }}
      >
        Держите камеру в середне страницы учебника
      </div>

      <div
        style={{
          position: "absolute",
          color: "white",
          display: "flex",
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </div>

      {arEnabled && (
        <video
          id="ar-video"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            objectFit: "cover",
            background: "black",
          }}
          ref={ref}
          loop
          autoPlay
          muted
          playsInline
        ></video>
      )}
      <Canvas
        camera={arEnabled ? { position: [0, 0, 0] } : props.camera}
        {...props}
      >
        <ARNftProvider
          video={ref}
          interpolationFactor={interpolationFactor}
          arEnabled={arEnabled}
          onMarkerLost={onMarkerLost}
          onMarkerFound={onMarkerFound}
        >
          {children}
        </ARNftProvider>
      </Canvas>
    </>
  );
};

export default React.memo(ARCanvas);
