import AuthStore from "./AuthStore";
import CommonStore from "./CommonStore";
import PostStore from "./PostStore";
import TasksStore from "./TasksStore";

export default class RootStore {
  constructor() {
    this.commonStore = new CommonStore();
    this.authStore = new AuthStore(this.commonStore);
    this.postStore = new PostStore(this.commonStore);
    this.tasksStore = new TasksStore(this.commonStore, this.postStore);
  }
}
