import { observer } from "mobx-react-lite";
import useStore from "../helpers/use-store";
import {
  Chart,
  LineElement,
  LineController,
  PointElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Line } from "react-chartjs-2";

import s from "./TaskPitchResultChart.module.css";

Chart.register(
  LineElement,
  LineController,
  PointElement,
  CategoryScale,
  LinearScale
);

const TaskPitchResultChart = () => {
  const { commonStore } = useStore();

  const data = {
    labels: commonStore.pitchResult,
    datasets: [
      {
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        borderWidth: 4,
        tension: 0.3,
        data: [...commonStore.pitchResult],
      },
      {
        fill: false,
        borderColor: "rgb(29, 143, 250)",
        borderWidth: 4,
        tension: 0.3,
        data: [...commonStore.nativePitch],
      },
    ],
  };

  const options = {
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        display: false,
        beginAtZero: true,
      },
      y: {
        display: false,
        beginAtZero: true,
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default observer(TaskPitchResultChart);
